@import './styles/colors';

body {
  background: $background;
}
.App {
  margin: 1rem;
  color: $text;

  .title {
    color: $primary;
    font-family: 'Helvetica', sans-serif;
    font-size: 2.8rem;
    margin-top: 0;
  }

  .template-input {
    background: $white;
    margin: 1rem 0 1rem 0;
    padding: 1rem;
    padding-bottom: 2rem;
    border-radius: 0.5rem;
    border: 1px solid $light-border;

    .input-field {
      margin: 1.4rem 0 0 0;

      label {
        color: $text;
        margin-right: 1rem;
        padding-bottom: 0.3rem;
        display: block;
        font-size: 1.4rem;
      }

      input {
        padding: 0.6rem 1rem;
        border: 1px solid $border;
        display: block;
        width: 25rem;
        color: $text;
        font-size: 1.6rem;
        border-radius: 0.5rem;

        &::placeholder {
          color: $light-text;
        }
      }
    }

    .colors {
      display: flex;

      .color {
        width: 10rem;
        height: 10rem;
        border: 2px solid $border;
        border-radius: 0.5rem;
        margin-right: 1rem;
        cursor: pointer;
        font-size: 1.6rem;
        color: $white;
        display: flex;
        text-transform: capitalize;
        justify-content: center;
        align-items: center;

        &.green {
          background: $build;
        }

        &.purple {
          background: $maintain;
        }

        &.blue {
          background: $think;
        }

        &.active {
          border: 2px solid $text;
        }

        &:hover,
        &:focus {
          border: 2px solid lighten($text, 25%);
          outline: none;
        }
      }
    }
  }

  .email-template {
    background: $white;
    border: 1px solid $light-border;
    border-radius: 0.5rem;
    margin: 1rem 0;
    padding: 1rem;
  }

  .copy-wrapper {
    position: relative;
    .copy-button {
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      font-size: 1.4rem;
      background-color: $primary;
      color: $white;
      border: 0;

      &:focus,
      &:hover {
        cursor: pointer;
        outline: none;
        background-color: lighten($primary, 15%);
      }
    }

    .copy-message {
      font-size: 1.2rem;
      color: $light-text;
      font-style: italic;

      top: 0.6rem;
      left: 14rem;

      position: absolute;
      animation-name: slide-in;
      animation-duration: 300ms;
      animation-timing-function: ease-out;
    }
  }
}

@keyframes slide-in {
  from {
    left: 13rem;
    opacity: 0;
  }
  to {
    left: 14rem;
    opacity: 1;
  }
}
