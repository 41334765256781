$white: #fff;
$text: #3f484f;
$light-text: #9ba5ac;
$background: #f3f3f3;
$border: #ccc;
$light-border: #e9e8e8;

$primary: #102548;

$think: #009ac7;
$build: #6cc7b4;
$maintain: #7159a6;
